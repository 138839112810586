import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { UnionTitle } from "../components/UnionTitle";
import { UnionOverviewTable } from "../components/UnionOverviewTable";
import { UnionHistoryTable } from "../components/UnionHistoryTable";
import { mediaQuery, smartPhone } from "../constants/styles";

const mapContainer = css`
  width: 80%;
  margin: 0 auto;
  padding-top: 100px;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`;

const map = css`
  width: 100%;
  height: 280px;
`;

export default function UnionOverview({ data, location }) {
  const siteMetadata = data?.site?.siteMetadata
  const title = "Union";
  const subTitle = "組合概要";
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  return (
    <Layout location={pathname}>
      <Head props={siteMetadata} />
      <UnionTitle title={[title, subTitle]} />
      <UnionOverviewTable />
      <div css={mapContainer}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.842466480518!2d135.80165321566247!3d34.683925180438706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60013bb3fb459ed1%3A0xa065003fbbe53d61!2z44CSNjMwLTgwMTMg5aWI6Imv55yM5aWI6Imv5biC5LiJ5p2h5aSn6Lev77yR5LiB55uu77yR4oiS77yY77yX!5e0!3m2!1sja!2sjp!4v1610597786666!5m2!1sja!2sjp"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          css={map}
        ></iframe>
      </div>
      <UnionHistoryTable />
    </Layout>
  );
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title,
        siteUrl,
        description
      }
    }
    allMicrocmsNews {
      nodes {
        daytime
        newsImage1 {
          url
        }
        newsImage2 {
          url
        }
        newsImage3 {
          url
        }
        newsImage4 {
          url
        }
        newsText
        newsTitle
        id
      }
    }
  }
`
