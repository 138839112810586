import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const wrapper = css`
  max-width: 1480px;
  width: 100%;
`;

const container = css`
  width: 90%;
  margin: 0 auto;
`;

const titleArea = css`
  width: 100%;
  padding-top: 121px;
  text-align: center;
  ${mediaQuery[smartPhone]} {
    padding-top: 109px;
  }
`;

const title = css`
  font-family: 'Noto Serif JP', serif;
  font-size: 14px;
  color: ${color.mainD};
  ${mediaQuery[smartPhone]} {
    font-size: 12px;
  }
`;

const subTitle = css`
  display: block;
  padding-top: 24px;
  padding-bottom: 26px;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  color: #5F5450;
  ${mediaQuery[smartPhone]} {
    padding-top: 12px;
    padding-bottom: 16px;
    font-size: 18px;
  }
`;

const border = css`
  width: 80px;
  height: 2px;
  background-color: ${color.gray};
  margin: 0 auto 71px auto;
  ${mediaQuery[smartPhone]} {
    width: 60px;
    height: 1px;
    margin: 0 auto 40px auto;
  }
`;

export const UnionTitle = (props) => {
  const en = 0;
  const ja = 1;
  return (
    <div css={wrapper}>
      <div css={container}>
        <div css={titleArea}>
          <h2 css={title}>{props.title[en]}</h2>
          <p css={subTitle}>{props.title[ja]}</p>
          <div css={border}></div>
        </div>
      </div>
    </div>
  );
};
