import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";
import backgroundImage5 from "../images/home-5-5.png";

const wrapper = css`
  max-width: 1480px;
  width: 100%;
  background: ${color.whiteGray};
  margin: 0 auto;
  padding-bottom: 128px;
  text-align: center;
  ${mediaQuery[smartPhone]} {
    padding-bottom: 76px;
  }
`;

const bgWhiteSp = css`
  display: none;
  ${mediaQuery[smartPhone]} {
    display: block;
    height: 162px;
    background-color: ${color.white};
  }
`

const container = css`
  width: 80%;
  margin: -60px auto 0 auto;
  ${mediaQuery[smartPhone]} {
    margin: -470px auto 0 auto;
  }
`;

const imageAria = css`
  margin: 0 auto;
  width: 100%;
  height: 469px;
  background-image: url(${backgroundImage5});
`;

const titleAriaPc = css`
  margin: 0 auto;
  padding-top: 172px;
  padding-bottom: 126px;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`;

const titleAriaSp = css`
  display: none;
  ${mediaQuery[smartPhone]} {
    display: block;
    margin: 0 auto;
    padding-top: 70px;
    padding-bottom: 20px;
  }
`;

const subTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.textSize};
  font-weight: bold;
  color: ${color.mainD};
  line-height: 20px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    line-height: 17px;
  }
`;

const title = css`
  display: block;
  padding-top: 24px;
  padding-bottom: 26px;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  color: ${color.black};
  line-height: 52px;
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
    line-height: 26px;
    padding-top: 12px;
    padding-bottom: 16px;
  }
`;

const titleBorder = css`
  margin: 0 auto;
  width: 80px;
  height: 2px;
  background: ${color.mainD};
  ${mediaQuery[smartPhone]} {
    width: 60px;
    height: 1px;
    background: ${color.gray};
  }
`;

const tableContainer = css`
  margin: 0 auto;
  text-align: start;
  letter-spacing: 0.15em;
`;

const rowContainer = css`
  display: flex;
  align-items: center;
  font-size: ${font.textSize};
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    line-height: 205%;
    flex-direction: column;
    align-items: start;
  }
`;

const dateTime = css`
  padding: 22px 0;
  width: 20%;
  color: ${color.mainD};
  ${mediaQuery[smartPhone]} {
    padding: 0;
  }
`;

const text = css`
  padding: 20px;
  width: 80%;
  font-size: ${font.textsize2};
  ${mediaQuery[smartPhone]} {
    width: 100%;
    padding: 0 0 12px 0;
    font-size: ${font.ButtonTextSize};
  }
`;

const span = css`
  padding: 4px;
`;

export const UnionHistoryTable = () => {
  return (
    <div css={wrapper}>
      <div css={bgWhiteSp}>
        <div css={titleAriaSp}>
          <p css={subTitle}>History</p>
          <h3 css={title}>組合の歴史</h3>
          <div css={titleBorder}></div>
        </div>
      </div>
      <div css={imageAria}>
        <div css={titleAriaPc}>
          <p css={subTitle}>History</p>
          <h3 css={title}>組合の歴史</h3>
          <div css={titleBorder}></div>
        </div>
      </div>
      <section css={container}>
        <div css={tableContainer}>
          <div css={rowContainer}>
            <p css={dateTime}>1960.02</p>
            <span css={span}></span>
            <p css={text}>
              中小企業等協同組合法に基づき、奈良市医師協同組合を設立。
            </p>
          </div>
          <div css={rowContainer}>
            <p css={dateTime}>1960.02</p>
            <span css={span}></span>
            <p css={text}>
              寝具業者の協力を得て、医療用寝具の共同選択施設運営により病院への寝具提供を行う。
            </p>
          </div>
          <div css={rowContainer}>
            <p css={dateTime}>1961.08</p>
            <span css={span}></span>
            <p css={text}>
              奈良県医師協同組合に名称変更し、地域を奈良市から奈良県一円とする。
            </p>
          </div>
          <div css={rowContainer}>
            <p css={dateTime}>1964.05</p>
            <span css={span}></span>
            <p css={text}>奈良県医師協同組合に名称変更。</p>
          </div>
          <div css={rowContainer}>
            <p css={dateTime}>1972.04</p>
            <span css={span}></span>
            <p css={text}>
              准看護学院を開校し組合員医療機関の看護要員の確保に努める。
            </p>
          </div>
          <div css={rowContainer}>
            <p css={dateTime}>1993.04</p>
            <span css={span}></span>
            <p css={text}>
              准看護学院の運営を社団法人奈良県病院協会に委譲する。
            </p>
          </div>
          <div css={rowContainer}>
            <p css={dateTime}>2005.09</p>
            <span css={span}></span>
            <p css={text}>
              損害保険代理店業務登録、医業経営セカンドオピニオン事業を開始。
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
