import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const wrapper = css`
  width: 100%;
  margin: 0 auto;
  }
`;

const listContainer = css`
  width: 80%;
  font-size: ${font.textsize2};
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    width: 100%;
  }
`;

const flexContainer = css`
  display: flex;
  margin-top: 1px;
  height: auto;
`;

const flexContainerPc = css`
  display: flex;
  margin-top: 1px;
  height: auto;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`;

const flexContainerSp = css`
  display: none;
  margin-top: 1px;
  height: auto;
  ${mediaQuery[smartPhone]} {
    display: flex;
  }
`;

const index = css`
  max-width: 310px;
  width: 30%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.mainL};
  color: ${color.white};
  ${mediaQuery[smartPhone]} {
    width: 106px;
    min-height: 44px;
    font-size: ${font.ButtonTextSize};
  }
`;

const text = css`
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: ${color.whiteGray};
  line-height: 27px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    line-height: 150%;
    padding: 15px;
  }
`;

const even = css`
  background-color: ${color.white};
`;


export const UnionOverviewTable = () => {
  return (
    <div css={wrapper}>
      <ul css={listContainer}>
        <li css={flexContainer}>
          <div css={index}>組合名</div>
          <div css={text}>奈良県医師協同組合</div>
        </li>
        <li css={flexContainer}>
          <div css={index}>所在地</div>
          <div css={[text, even]}>
            奈良市三条大路一丁目1番87号 奈良市役所前三和ビル2階
          </div>
        </li>
        <li css={flexContainerPc}>
          <div css={index}>電話/FAX番号</div>
          <div css={text}>0742-34-7991 / 0742-34-7992</div>
        </li>
        <li css={flexContainerSp}>
          <div css={index}>
            電話/<br></br>FAX番号
          </div>
          <div css={text}>0742-34-7991 / 0742-34-7992</div>
        </li>
        <li css={flexContainer}>
          <div css={index}>理事長</div>
          <div css={[text, even]}>勝井 建彦</div>
        </li>
        <li css={flexContainer}>
          <div css={index}>組合員数</div>
          <div css={text}>組合員数　558名　賛助会員441名（令和4年3月現在）</div>
        </li>
        <li css={flexContainer}>
          <div css={index}>出資資本金</div>
          <div css={[text, even]}>17,460,000円（令和4年3月現在）</div>
        </li>
        <li css={flexContainer}>
          <div css={index}>業務内容</div>
          <div css={text}>
            【購買事業】
            <br />
            組合員の診療業務に必要な医療機器・消耗品、書籍、生活用品等
            の斡旋販売
            <br />
            <br />
            【寝具事業】
            <br />
            組合員医療機関への医療寝具類のリースサービス
            <br />
            <br />
            【福祉事業】
            <br />
            組合員への生命保険・損害保険の団体割引や小規模企業共済 の取扱
            <br />
            <br />
            【指定店事業】
            <br />
            組合員の経済的地位改善のための、指定店特約による購入斡旋
            <br />
            【医業経営セカンドオピニオン事業】
            <br />
            医業経営セミナー・講座等を通じ医業経営のサポート
            <br />
          </div>
        </li>
      </ul>
    </div>
  )
};
